import { combineReducers } from 'redux-immutable';
import { Reducer } from 'redux';
import docUpload from './doc-upload/reducer';
import ui from './ui/reducer';

const rootReducer: Reducer = combineReducers({
  docUpload,
  ui,
});

export default rootReducer;
