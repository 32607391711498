import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { ProofButton } from '../../../ui/button/ProofButton';
import palette from '../../../ui/palette';
import SendMessageModal from './message';
import { useOptions } from '..';

export default function CoSignApplicants() {
  const { userDetails, handleNext } = useOptions();
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState(false);

  const applicants = useMemo(() => {
    return (
      userDetails?.application?.applicants?.filter((applicant: any) => applicant.role !== 'Guarantor' && applicant.role !== 'Non-Resident Co-sign') ?? []
    );
  }, [userDetails]);

  return (
    <Container maxWidth="xs" sx={{ px: 3 }}>
      <Typography variant="h2" sx={{ fontWeight: 500, textAlign: 'center', mb: { xs: 2, md: 2.5 } }}>
        {userDetails?.role === 'Guarantor' ? t('verification.guarantor.title') : t('verification.cosign.title')}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        {userDetails?.role === 'Guarantor' ? t('verification.guarantor.desc') : t('verification.cosign.desc')}
      </Typography>
      <Stack gap={2} mb={3}>
        {applicants.map((applicant: any) => (
          <Box key={applicant._id} sx={{ px: 1.5, py: 2, border: `1px solid #D4D7DC`, borderRadius: 2 }}>
            <Typography variant="subtitle1">{applicant.name}</Typography>
            <Typography variant="body2">{`${userDetails?.application?.property_name} | ${userDetails?.application?.unit}`}</Typography>
          </Box>
        ))}
      </Stack>
      <Box sx={{ textAlign: 'center' }}>
        <Button variant="text" sx={{ textTransform: 'initial', textAlign: 'center', color: palette.primary[30] }} onClick={() => setShowMessage(true)}>
          {t('verification.guarantor.wrong')}
        </Button>
      </Box>
      <Box sx={{ position: 'absolute', bottom: '32px', width: 'calc(100vw - 48px)', maxWidth: '396px' }}>
        <ProofButton variant="contained" fullWidth onClick={handleNext}>
          {t('verification.guarantor.button')}
        </ProofButton>
      </Box>
      {showMessage && userDetails && <SendMessageModal userDetails={userDetails} onClose={() => setShowMessage(false)} />}
    </Container>
  );
}
