import axios, { AxiosError } from 'axios';
import { BASE_URL } from '../constants/constants';
import { GetIncomeRequest, IPlaidVerification, IncomeSuccessRequest, IncomeRequest } from './plaid.types';

const apiInstance = axios.create({
  baseURL: BASE_URL,
});

export const getApplicantFromToken = async (token: string) => {
  try {
    const response = await apiInstance.get('/application/applicant', {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.log('error', (err as AxiosError).message);
  }
};

export const fetchLinkToken = async (incomeBody: IncomeRequest) => {
  try {
    const response = await apiInstance.post('/plaid/generate_link_token', incomeBody);
    return response;
  } catch (err) {
    console.log('Not able to generate link token', (err as AxiosError).message);
  }
};

export const getPayrollIncome = async (body: GetIncomeRequest) => {
  try {
    const response = await apiInstance.post('/plaid/get_payroll_income', body);
    return response.data;
  } catch (err) {
    console.log('Not able to get payroll income', (err as AxiosError).message);
  }
};

export const getBankIncome = async (body: GetIncomeRequest) => {
  try {
    const response = await apiInstance.post('/plaid/get_bank_income', body);
    return response.data;
  } catch (err) {
    console.log('Not able to get bank income', (err as AxiosError).message);
  }
};

export const setIncomeSuccessful = async (body: IncomeSuccessRequest) => {
  try {
    const response = await apiInstance.post('/plaid/income_was_successful', body);
    return response.data;
  } catch (err) {
    console.log('Not able to update income connected', (err as AxiosError).message);
  }
};

export const getPlaidUserStatus = async (body: GetIncomeRequest) => {
  try {
    const response = await apiInstance.post('/plaid/get_user_info', body);
    return response.data;
  } catch (err) {
    console.log('Not able to get user info', (err as AxiosError).message);
  }
};

export const startPlaidVerification = async (body: IPlaidVerification) => {
  try {
    const response = await apiInstance.post('/plaid/verification', body);
    return response.data;
  } catch (err) {
    return (err as any).response?.data?.message || 'Verification failed.';
  }
};

export const updatePlaidUserDetails = async (plaidId: string, body: any) => {
  try {
    const response = await apiInstance.put(`/plaid/${plaidId}`, body);
    return response.data;
  } catch (err) {
    return (err as any).response?.data?.message || 'Unable to update plaid user.';
  }
};

export const contactSupport = async (body: any) => {
  try {
    const response = await apiInstance.post(`/application/contact-support`, body);
    return response.data;
  } catch (err) {
    return (err as any).response?.data?.message || 'Unable to send message to support team.';
  }
};
