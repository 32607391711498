import { Box, Container, Stack, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { ProofButton } from '../../../ui/button/ProofButton';
import { useOptions } from '..';

export default function IncomeVerificationIntro() {
  const { t } = useTranslation();
  const { handleNext } = useOptions();

  return (
    <>
      <Box sx={{ backgroundColor: 'primary.100', textAlign: 'center', pb: 3, pt: 4, mb: 3, display: { xs: 'block', md: 'none' } }}>
        <Box component="img" src="/images/transfer_money.svg" sx={{ width: '200px' }} />
      </Box>
      <Container maxWidth="xs" sx={{ px: 3 }}>
        <Typography variant="h2" sx={{ fontWeight: 500, mb: { xs: 1.5, md: 2.5 } }}>
          {t('verification.intro.title')}
        </Typography>
        <Typography variant="body1" sx={{ mb: { xs: 4, md: 3.5 } }}>
          {t('verification.intro.desc')}
        </Typography>
        <Box sx={{ textAlign: 'center', py: 2, mb: 2, display: { xs: 'none', md: 'block' } }}>
          <Box component="img" src="/images/transfer_money.svg" sx={{ width: '150px' }} />
        </Box>
        <Stack flexDirection="row" gap={1.5} mb={3}>
          <Box component="img" src="/images/group2.svg" sx={{ width: '32px', height: '32px' }} />
          <Typography variant="body1">{t('verification.intro.desc1')}</Typography>
        </Stack>
        <Stack flexDirection="row" gap={1.5} mb={4}>
          <Box component="img" src="/images/group1.svg" sx={{ width: '32px', height: '32px' }} />
          <Typography variant="body1">{t('verification.intro.desc2')}</Typography>
        </Stack>
        <Box sx={{ backgroundColor: 'primary.100', borderRadius: 1, p: 1, mb: 3 }}>
          <Stack flexDirection="row" gap={1.5} alignItems="center">
            <InfoOutlinedIcon />
            <Typography variant="body2">{t('verification.intro.information')}</Typography>
          </Stack>
        </Box>
        <ProofButton variant="contained" fullWidth onClick={handleNext}>
          {t('page.getStarted')}
        </ProofButton>
      </Container>
    </>
  );
}
