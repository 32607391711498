import { Record } from 'immutable';
import { getStore } from '../../utils/persist';
import { ActionTypes } from './action-types';
import {
  IAddQuestionConfig,
  IDocUploadState,
  IResetState,
  ISaveStep,
  ISetCurrentStep,
  ISetQuestionsConfig,
  ISetSubmitted,
  ISetUserDetails,
} from './types';

type Actions = ISetUserDetails | ISetCurrentStep | IAddQuestionConfig | ISetQuestionsConfig | ISetSubmitted | ISaveStep | IResetState;

const persistedStore = getStore();

const record = Record<IDocUploadState>({
  userDetails: persistedStore?.docUpload?.userDetails || {},
  currentStep: persistedStore?.docUpload?.currentStep || 0,
  questionsConfig: persistedStore?.docUpload?.questionsConfig || {
    paystubs: {
      count: 1,
    },
    bankStatements: {
      count: 2,
    },
  },
  submitted: persistedStore?.docUpload?.submitted || false,
});

class DocUploadState extends record implements IDocUploadState {}

export default function reducer(state = new DocUploadState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetUserDetails: {
      return state.set('userDetails', action.payload.userDetails);
    }
    case ActionTypes.SetCurrentStep: {
      return state.set('currentStep', action.payload.currentStep);
    }
    case ActionTypes.SetQuestionsConfig: {
      return state.set('questionsConfig', action.payload.questionsConfig);
    }
    case ActionTypes.SetSubmitted: {
      return state.set('submitted', action.payload.submitted);
    }
    case ActionTypes.SaveStep: {
      return state.set('userDetails', {
        ...state.userDetails,
        ...action.payload.userDetails,
      });
    }
    case ActionTypes.ResetState: {
      return state
        .set('userDetails', {})
        .set('currentStep', 0)
        .set('questionsConfig', {
          paystubs: {
            count: 1,
          },
          bankStatements: {
            count: 2,
          },
        })
        .set('submitted', false);
    }
    case ActionTypes.AddQuestionConfig: {
      return state.set('questionsConfig', {
        ...state.questionsConfig,
        [action.payload.questionKey]: {
          ...(state.questionsConfig[action.payload.questionKey] || {}),
          ...action.payload.questionConfig,
        },
      });
    }
    default: {
      return state;
    }
  }
}
