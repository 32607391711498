import { Box, Button, Typography } from '@mui/material';
import palette from '../../ui/palette';
import { useTranslation } from 'react-i18next';
import LinkLoader, { IncomeType } from '../../containers/plaid/LinkLoader';

export default function UploadIncomeDocuments() {
  const { t } = useTranslation();

  return (
    // <Box sx={{ backgroundColor: palette.primary[10], px: 2, py: 2, borderRadius: 2 }}>
    //   <Box component="img" src="/images/document.svg" sx={{ width: '40px', height: '40px', mb: 0.5 }} />
    //   <Typography variant="h6" mb={1.5}>
    //     {t('verification.type.document.title')}
    //   </Typography>
    //   <Typography variant="body2" mb={1}>
    //     {t('verification.type.document.desc')}
    //   </Typography>
    //   <Typography variant="caption" mb={3} component="div">
    //     {t('verification.type.document.info')}
    //   </Typography>
    //   <Box sx={{ textAlign: 'center', px: [0, 0, 4] }}>
    //     <LinkLoader variant="outlined" buttonText={t('verification.type.document.button')} income={true} incomeType={IncomeType.Payroll} />
    //   </Box>
    // </Box>
    <Box>
      <LinkLoader variant="text" buttonText={t('verification.type.document.button')} income={true} incomeType={IncomeType.Payroll} isDocument />
    </Box>
  );
}
