import { Box, Stack, Typography } from '@mui/material';
import palette from '../../ui/palette';
import LinkLoader, { IncomeType } from '../../containers/plaid/LinkLoader';
import { useTranslation } from 'react-i18next';

export enum VerifyIncomeType {
  Payroll_Provider = 'Payroll_Provider',
  Bank_Account = 'Bank_Account',
  Paystubs = 'Paystubs',
}

interface Props {
  type: VerifyIncomeType;
}

export default function VerificationType({ type }: Props) {
  const { t } = useTranslation();

  const renderProviders = () => {
    if (type === VerifyIncomeType.Payroll_Provider) {
      return (
        <Stack gap={1.5} mb={4}>
          <Typography variant="caption">{t('verification.type.slide1.providerList')}</Typography>
          <Box sx={{ display: 'flex', gap: 1, height: '11px', '& img': { height: '11px' } }}>
            <Box component="img" src="/images/adp.svg" />
            <Box component="img" src="/images/paycom.svg" />
            <Box component="img" src="/images/workday.svg" />
            <Box component="img" src="/images/trinet.svg" />
            <Box component="img" src="/images/paycon.svg" />
          </Box>
        </Stack>
      );
    }
    if (type === VerifyIncomeType.Bank_Account) {
      return (
        <Stack gap={1} mb={4}>
          <Typography variant="caption">{t('verification.type.slide2.providerList')}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: '10px', '& img': { height: '10px' } }}>
            <Box component="img" src="/images/chase.svg" />
            <Box component="img" src="/images/citibank.svg" />
            <Box component="img" src="/images/bankOfAmerica.svg" />
            <Box component="img" src="/images/wellsfargo.svg" />
            <Typography variant="overline" sx={{ color: 'grey.400', letterSpacing: 0 }}>
              +11,000 {t('verification.type.slide2.more')}
            </Typography>
          </Box>
        </Stack>
      );
    }
    return (
      <Stack gap={1.5} mb={4}>
        <Typography variant="caption">{t('verification.type.slide3.providerList')}</Typography>
        <Typography variant="caption" sx={{ height: '16px' }}>
          {t('verification.type.slide3.subInfo')}
        </Typography>
      </Stack>
    );
  };

  return (
    <Box sx={{ backgroundColor: palette.primary[10], px: 2, py: 2, borderRadius: 2, height: '100%', flex: 1 }}>
      <Box component="img" src={iconLinks[type]} sx={{ width: '40px', height: '40px', mb: 1.5 }} />
      <Typography variant="h6" mb={1.5}>
        {t(titles[type])}
      </Typography>
      <Typography variant="body2" mb={2} sx={{ height: { xs: '60px', md: '60px' } }}>
        {t(descriptions[type])}
      </Typography>
      {renderProviders()}
      <Box sx={{ textAlign: 'center', px: 0 }}>
        <LinkLoader buttonText={t(buttons[type])} income={true} incomeType={incomeTypes[type]} />
      </Box>
      <Box sx={{ backgroundColor: 'primary.200', p: 1, borderRadius: 1 }}>
        <Typography variant="caption">
          <strong>{t('verification.type.bestFor')}</strong> <span>{t(bestFor[type])}</span>
        </Typography>
      </Box>
    </Box>
  );
}

const iconLinks = {
  [VerifyIncomeType.Payroll_Provider]: `/images/payrollProvider.svg`,
  [VerifyIncomeType.Bank_Account]: `/images/bankAccount.svg`,
  [VerifyIncomeType.Paystubs]: `/images/paystubs.svg`,
};

const titles = {
  [VerifyIncomeType.Payroll_Provider]: 'verification.type.slide1.title',
  [VerifyIncomeType.Bank_Account]: 'verification.type.slide2.title',
  [VerifyIncomeType.Paystubs]: 'verification.type.slide3.title',
};

const descriptions = {
  [VerifyIncomeType.Payroll_Provider]: 'verification.type.slide1.desc',
  [VerifyIncomeType.Bank_Account]: 'verification.type.slide2.desc',
  [VerifyIncomeType.Paystubs]: 'verification.type.slide3.desc',
};

const buttons = {
  [VerifyIncomeType.Payroll_Provider]: 'verification.type.slide1.button',
  [VerifyIncomeType.Bank_Account]: 'verification.type.slide2.button',
  [VerifyIncomeType.Paystubs]: 'verification.type.slide3.button',
};

const bestFor = {
  [VerifyIncomeType.Payroll_Provider]: 'verification.type.slide1.info',
  [VerifyIncomeType.Bank_Account]: 'verification.type.slide2.info',
  [VerifyIncomeType.Paystubs]: 'verification.type.slide3.info',
};

const incomeTypes = {
  [VerifyIncomeType.Payroll_Provider]: IncomeType.Payroll,
  [VerifyIncomeType.Bank_Account]: IncomeType.Bank,
  [VerifyIncomeType.Paystubs]: IncomeType.Payroll,
};
