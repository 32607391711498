import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import palette from '../palette';

export const TSInput = styled(TextField)({
  width: '100%',

  '& .MuiInputBase-input': {
    padding: '.75rem 1rem .75rem 1rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1.5px solid',
    borderColor: palette.grey[50],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: palette.grey[50],
      borderRadius: 8,
    },
    '&:not(.Mui-error):hover fieldset': {
      borderColor: palette.grey[50],
    },
    '&.Mui-focused:not(.Mui-error) fieldset': {
      // border: "1.5px solid",
      borderColor: palette.grey[50],
    },
  },

  '& .MuiInputLabel-root': {
    transform: 'translate(16px, 12px) scale(1)',
    '&.Mui-focused, &.MuiFormLabel-filled': {
      transform: 'translate(16px, -9px) scale(0.75)',
    },
  },
  '& .MuiInputLabel-root:not(.Mui-error)': {
    color: palette.grey[40],
  },
  '& label': {
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 400,
    // color: palette.grey[40],
  },
});
