import { Record } from 'immutable';
import { ActionTypes } from './action-types';
import { ISetLinkToken, IUpdatePlaidUserInfo, ISetPropertyMapping, IUIState, ISetVerificationStep } from './types';

type Actions = ISetPropertyMapping | ISetLinkToken | IUpdatePlaidUserInfo | ISetVerificationStep;

const record = Record<IUIState>({
  propertyMapping: {},
  linkToken: null,
  verificationStep: 1,
  plaidUserInfo: {
    email: '',
    propertyName: '',
    unitNumber: '',
    fullName: '',
    phoneNumber: '',
    applicant_id: '',
    plaidUserId: '',
  },
});

class UIState extends record implements IUIState {}

export default function reducer(state = new UIState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetPropertyMapping: {
      return state.set('propertyMapping', action.payload.propertyMapping);
    }
    case ActionTypes.SetLinkToken: {
      return state.set('linkToken', action.payload.linkToken);
    }
    case ActionTypes.UpdatePlaidUserInfo: {
      return state.set('plaidUserInfo', {
        ...state.plaidUserInfo,
        ...action.payload.userInfo,
      });
    }
    case ActionTypes.SetVerificationStep: {
      return state.set('verificationStep', action.payload.step);
    }
    default: {
      return state;
    }
  }
}
