const palette = {
  white: 'white',
  black: 'black',
  progressBar: '#0E1C37',
  primary: {
    10: '#F1F3FD',
    20: '#E4E8FC',
    30: '#6078EA',
    40: '#A9B4EE',
    50: '#0E1C37',
    80: '#2C366B',
    90: '#131D53',
  },
  secondary: {
    10: '#18A0FB',
    20: '#E9F0FC',
  },
  grey: {
    20: '#2c364d0a',
    30: '#F5F5F5',
    40: '#7A7A7A',
    50: '#D4D7DC',
    60: '#272937',
    70: '#646464',
    80: '#52505E',
    90: '#212121',
  },
  success: {
    60: '#27AB83',
  },
  error: {
    60: '#BA2525',
  },
  text: {
    primary: '#52505E',
    secondary: '#52505E',
  },
};

export default palette;
