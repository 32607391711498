import { createTheme } from '@mui/material/styles';
import palette from './palette';

export const onDesktop = `@media (min-width:425px)`;

const theme = createTheme({
  palette: {
    common: {
      white: palette.white,
    },
    primary: {
      main: palette.primary[50],
      dark: palette.primary[90],
      light: palette.primary[30],
      100: palette.primary[10],
      200: palette.primary[20],
      400: palette.primary[40],
      800: palette.primary[80],
    },
    secondary: {
      main: palette.secondary[10],
      100: palette.secondary[10],
    },
    success: {
      main: palette.success[60],
      600: palette.success[60],
    },
    error: {
      main: palette.error[60],
      600: palette.error[60],
    },
    grey: {
      300: palette.grey[30],
      400: palette.grey[40],
      500: palette.grey[50],
      600: palette.grey[60],
      700: palette.grey[70],
      900: palette.grey[90],
    },
    text: {
      primary: palette.text.primary,
      secondary: palette.text.secondary,
    },
  },
});

const customTheme = createTheme(theme, {
  typography: {
    allVariants: {
      color: palette.text.primary,
    },
    h1: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 48,
      lineHeight: 1.08,
      fontWeight: 400,
    },
    h2: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 32,
      lineHeight: 1.22,
      fontWeight: 400,
      [onDesktop]: {
        fontSize: 36,
      },
    },
    h3: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 32,
      lineHeight: 36 / 32,
    },
    h4: {
      fontFamily: ['Hellix', 'serif'].join(','),
      letterSpacing: 0,
      fontSize: 24,
      lineHeight: 32 / 24,
    },
    h5: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 28 / 20,
    },
    h6: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 14,
      lineHeight: 1.5,
      letterSpacing: 0,
      [onDesktop]: {
        fontSize: 16,
      },
    },
    subtitle2: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.43,
    },
    body2: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: 0,
    },
    overline: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 10,
      lineHeight: 1.6,
      letterSpacing: 0.4,
    },
    caption: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.02em',
    },
  },
});

export default customTheme;
