import { ActionTypes } from './action-types';
import { ISetPropertyMapping } from './types';

export const setPropertyMapping = (propertyMapping: { [key: string]: string }): ISetPropertyMapping => ({
  type: ActionTypes.SetPropertyMapping,
  payload: {
    propertyMapping,
  },
});

export const setLinkToken = (linkToken: string) => ({
  type: ActionTypes.SetLinkToken,
  payload: {
    linkToken,
  },
});

export const updatePlaidUserInfo = (userInfo: any) => ({
  type: ActionTypes.UpdatePlaidUserInfo,
  payload: {
    userInfo,
  },
});

export const setVerificationStep = (step: number) => ({
  type: ActionTypes.SetVerificationStep,
  payload: {
    step,
  },
});
