import { Box, Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router';
import VerificationHeader from '../../components/verification/VerificationHeader';
import { setVerificationStep } from '../../store/ui/actions';
import { getVerificationStep } from '../../store/ui/selector';
import { ApplicantionFromToken } from '../../models/doc-upload.model';
import { useEffect, useState } from 'react';
import { getApplicantFromToken } from '../../services/plaid.service';
import { FullStory } from '@fullstory/browser';

type ContextType = { userDetails: ApplicantionFromToken | null; handleNext: VoidFunction; handleBack: VoidFunction };

export default function IncomeVerification() {
  const dispatch = useDispatch();
  let { propertyCode } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchObj = new URLSearchParams(search);
  const token = searchObj.get('token');

  const verificationStep = useSelector(getVerificationStep);

  const [userFromUrl, setUserFromUrl] = useState<ApplicantionFromToken | undefined>(undefined);
  const isGuarantor = userFromUrl?.role === 'Guarantor' || userFromUrl?.role === 'Non-Resident Co-sign';

  const initailize = async () => {
    // get details from token
    let uD: Partial<ApplicantionFromToken> = {};
    if (token) {
      const user = await getApplicantFromToken(token!!);
      if (user) {
        uD = user.data;
        FullStory('setIdentity', {
          uid: uD.applicant_id,
        });
        FullStory('setProperties', {
          type: 'user',
          properties: {
            propertyCode: propertyCode,
            propertyName: uD.propertyName,
          },
        });
      }
    }
    setUserFromUrl(uD as any);
  };

  useEffect(() => {
    if (propertyCode) {
      initailize();
    }
  }, [propertyCode]);

  const handleNext = () => {
    switch (verificationStep) {
      case 1:
        dispatch(setVerificationStep(verificationStep + 1));
        if (isGuarantor) {
          navigate(`/${propertyCode}/verification/cosign?token=${token}`);
        } else {
          navigate(`/${propertyCode}/verification/intro?token=${token}`);
        }
        break;
      case 2:
        dispatch(setVerificationStep(verificationStep + 1));
        if (isGuarantor) {
          navigate(`/${propertyCode}/verification/intro?token=${token}`);
        } else {
          navigate(`/${propertyCode}/verification/types?token=${token}`);
        }
        break;
      case 3:
        dispatch(setVerificationStep(verificationStep + 1));
        if (isGuarantor) {
          navigate(`/${propertyCode}/verification/types?token=${token}`);
        } else {
          navigate(`/${propertyCode}/verification/confirmation?token=${token}`);
        }
        break;
      case 4:
        dispatch(setVerificationStep(verificationStep + 1));
        navigate(`/${propertyCode}/verification/confirmation?token=${token}`);
        break;
    }
  };

  const handleBack = () => {
    switch (verificationStep) {
      case 2:
        dispatch(setVerificationStep(verificationStep - 1));
        navigate(`/${propertyCode}/verification?token=${token}`);
        break;
      case 3:
        dispatch(setVerificationStep(verificationStep - 1));
        navigate(`/${propertyCode}/verification/intro?token=${token}`);
        break;
    }
  };

  const showLogo = verificationStep === 1 || (verificationStep === 2 && isGuarantor);

  return (
    <>
      <VerificationHeader showLogo={showLogo} verificationStep={verificationStep} />
      <Toolbar sx={{ display: { xs: showLogo ? 'flex' : 'none', md: 'flex' } }} />
      <Box sx={{ color: 'grey.900', pt: { xs: showLogo ? 5.5 : 0, md: 5.5 }, pb: 4 }}>
        <Outlet context={{ userDetails: userFromUrl, handleNext, handleBack }} />
      </Box>
    </>
  );
}

export function useOptions() {
  return useOutletContext<ContextType>();
}
