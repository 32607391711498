import { Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProofButton } from '../../../ui/button/ProofButton';
import { ApplicantionFromToken } from '../../../models/doc-upload.model';
import { contactSupport } from '../../../services/plaid.service';

interface Props {
  userDetails: ApplicantionFromToken;
  onClose: VoidFunction;
}

export default function SendMessageModal({ userDetails, onClose }: Props) {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');

  const handleSendMessage = async () => {
    await contactSupport({
      message,
      applicant_id: userDetails.applicant_id,
      application_id: userDetails.application.application_id,
    });

    onClose();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ px: 2 }} variant="h5">
        {t('verification.cosign.message.title')}
      </DialogTitle>
      <DialogContent sx={{ px: 2, pb: 2 }}>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {t('verification.cosign.message.desc')}
        </Typography>
        <TextField
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder={t('verification.cosign.message.placeholder')}
          multiline
          rows={5}
          fullWidth
          InputProps={{ sx: { height: '152px' } }}
          sx={{ pb: 2 }}
        />
        <ProofButton disabled={!message} variant="contained" fullWidth onClick={handleSendMessage}>
          {t('verification.cosign.message.button')}
        </ProofButton>
      </DialogContent>
    </Dialog>
  );
}
