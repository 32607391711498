import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box, Container, Typography } from '@mui/material';
import { ProofButton } from '../../../ui/button/ProofButton';
import { useTranslation } from 'react-i18next';

export default function IncomeVerificationReceived() {
  const { t } = useTranslation();

  const handleClose = () => {
    // window.close();
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'primary.100', textAlign: 'center', mb: 3, pt: 8.25, display: { xs: 'block', md: 'none' } }}>
        <Box component="img" src="/images/received.svg" sx={{ height: '240px' }} />
      </Box>
      <Container maxWidth="xs" sx={{ px: 3 }}>
        <Typography variant="h2" mb={5} sx={{ fontWeight: 500 }}>
          {t('verification.received.title')}
        </Typography>
        <Box sx={{ textAlign: 'center', mb: 5, display: { xs: 'none', md: 'block' } }}>
          <Box component="img" src="/images/received.svg" sx={{ width: '150px' }} />
        </Box>
        <Typography variant="h5" sx={{ mb: { xs: 2, md: 4 } }}>
          {t('verification.received.next')}
        </Typography>
        <Timeline sx={{ p: 0, mb: 4 }}>
          <TimelineItem sx={{ '&::before': { display: 'none' } }}>
            <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: 'primary.800' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{t('verification.received.desc1')}</TimelineContent>
          </TimelineItem>
          <TimelineItem sx={{ '&::before': { display: 'none' } }}>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ backborderColor: 'primary.800' }} />
            </TimelineSeparator>
            <TimelineContent>{t('verification.received.desc2')}</TimelineContent>
          </TimelineItem>
        </Timeline>
        <ProofButton variant="contained" fullWidth onClick={handleClose}>
          {t('page.close')}
        </ProofButton>
      </Container>
    </>
  );
}
