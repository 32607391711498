import { styled } from '@mui/material/styles';
import palette from '../palette';
import { Button } from '@mui/material';

export const ProofButton = styled(Button)({
  textTransform: 'none',
  padding: '0.875rem 1.25rem 0.875rem 1.25rem',
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 600,
  borderRadius: 8,
  boxShadow: 'none',
  '&.MuiButton-contained': {
    backgroundColor: palette.primary[30],
    '&:hover': {
      backgroundColor: palette.primary[50],
    },
  },
  '&.MuiButton-outlined': {
    color: palette.primary[30],
    borderColor: 'rgba(39, 41, 55, 0.35)',
  },
  '&.Mui-disabled': {
    backgroundColor: palette.grey[30],
  },
  '&.MuiButton-outlined:hover': {
    backgroundColor: palette.white,
  },
  '&.MuiButton-textPrimary': {
    color: palette.primary[30],
  },
});
