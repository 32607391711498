import { AppBar, Button, Stack } from '@mui/material';
import palette from '../../ui/palette';
import { ReactComponent as Logo } from './../../illustrations/logo/proofup_logo.svg';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import { useTranslation } from 'react-i18next';

interface Props {
  showLogo: boolean;
  verificationStep: number;
}
export default function VerificationHeader({ showLogo }: Props) {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (e: any) => {
    const languageValue = i18n.language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(languageValue);
  };

  return (
    <AppBar
      sx={{
        backgroundColor: palette.secondary[20],
        color: palette.primary[20],
        p: 0,
        boxShadow: 'unset',
        display: { xs: showLogo ? 'flex' : 'none', md: 'flex' },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 2, px: { xs: 3, md: 15 } }}>
        <Logo />
        <Button
          variant="text"
          sx={{
            py: 1,
            pl: 1,
            pr: 14 / 8,
            lineHeight: 20 / 14,
            fontWeight: 600,
            color: 'primary.light',
          }}
          onClick={changeLanguageHandler}
        >
          <LanguageRoundedIcon sx={{ mr: 1 }} />
          {t('header.lang')}
        </Button>
      </Stack>
    </AppBar>
  );
}
