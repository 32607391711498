import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import theme from './ui/theme';
import store from './store/store';
import { Provider } from 'react-redux';
import './i18n';
import { init as initFullStory } from '@fullstory/browser';
import { ClerkProvider } from '@clerk/clerk-react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

initFullStory({ orgId: 'o-1CZ4ZR-na1', recordCrossDomainIFrames: true, devMode: process.env.REACT_APP_APP_ENV !== 'prod' });

const NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY =
  process.env.REACT_APP_APP_ENV !== 'prod' ? 'pk_test_Z2VudWluZS1raWQtMTcuY2xlcmsuYWNjb3VudHMuZGV2JA' : 'pk_live_Y2xlcmsucHJvb2Z1cC5jb20k';

root.render(
  <>
    <ClerkProvider publishableKey={NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Provider>
    </ClerkProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
