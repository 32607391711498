import { Route, Routes } from 'react-router-dom';
import IncomeVerification from '../containers/verification';
import IncomeVerificationAccount from '../containers/verification/account';
import IncomeVerificationIntro from '../containers/verification/intro';
import CoSignApplicants from '../containers/verification/cosign';
import IncomeVerificationTypes from '../containers/verification/type';
import IncomeVerificationReceived from '../containers/verification/received';

interface AppRoutesProp {}

export default function AppRoutes(props: AppRoutesProp): JSX.Element {
  return (
    <Routes>
      <Route path=":propertyCode/verification" element={<IncomeVerification />}>
        <Route index element={<IncomeVerificationAccount />} />
        <Route path="intro" element={<IncomeVerificationIntro />} />
        <Route path="cosign" element={<CoSignApplicants />} />
        <Route path="types" element={<IncomeVerificationTypes />} />
        <Route path="confirmation" element={<IncomeVerificationReceived />} />
      </Route>
      {/* <Route path="plaid/demo" element={<PlaidDemo />} /> */}
    </Routes>
  );
}
